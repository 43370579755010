* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

body{padding: 20px;}

#app {
  height: calc(100vh - 40px);
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  border: 1px solid #D2D2D3;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 20px;

  &>div {
    width: 50%;
    height: 100%;
  }


  #chatbot {
    padding-right: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;

    iframe#i {
      aspect-ratio: 19/9 !important;
      width: calc(100% - 30px);
      border: 2px solid black;
      border-radius: 10px;
      position: absolute;
      bottom: 70px;
    }

    .input {
      width: calc(100% - 30px);
      padding: 15px 20px 15px 20px;
      border-radius: 10px;
      height: auto;
      border: 2px solid black;
      display: flex;
      justify-content: start;
      position: absolute;
      bottom: 0;
      background-color: white;
      z-index: 20;
      textarea {
        height: 17px;
        max-height: 250px;
        border: none;
        background-color: transparent;
        outline: none !important;
        color: #787878;
        font-size: 14px;
        width: calc(100% - 45px);
        resize: none;
  
        &::placeholder {
            color: #939393;
            font-size: 14px;
        }
  
      }
      .envoyer {
        cursor: pointer;
        position: absolute;
        right: -10px;
        font-size: 40px;
        top: calc(50% - 2.5px);
        transform: translate(-50%, -50%);
        &:hover {
          color: rgb(73, 73, 73);
        }
      }
    }

    .reponseIA {
      background-color: #dedede;
      padding: 10px 15px;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    
  }


  #vscode {
    display: flex;
    flex-direction: column;
    justify-content: start;

    

    textarea {
      resize: vertical;
      font-size: 13px;
      padding: 10px;
      height: calc(100% / 3);
      margin: 7.5px 0;
      border-radius: 10px;
    }
  }
}