* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Rubik, sans-serif;
}

body {
  padding: 20px;
}

#app {
  background: #fff;
  border: 1px solid #d2d2d3;
  border-radius: 20px;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 40px);
  padding: 20px;
  display: flex;
  box-shadow: 0 4px 10px #0000001a;
}

#app > div {
  width: 50%;
  height: 100%;
}

#app #chatbot {
  flex-direction: column;
  justify-content: start;
  padding-right: 30px;
  display: flex;
  position: relative;
}

#app #chatbot iframe#i {
  border: 2px solid #000;
  border-radius: 10px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: 70px;
  aspect-ratio: 19 / 9 !important;
}

#app #chatbot .input {
  z-index: 20;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  justify-content: start;
  width: calc(100% - 30px);
  height: auto;
  padding: 15px 20px;
  display: flex;
  position: absolute;
  bottom: 0;
}

#app #chatbot .input textarea {
  color: #787878;
  resize: none;
  background-color: #0000;
  border: none;
  width: calc(100% - 45px);
  height: 17px;
  max-height: 250px;
  font-size: 14px;
  outline: none !important;
}

#app #chatbot .input textarea::placeholder {
  color: #939393;
  font-size: 14px;
}

#app #chatbot .input .envoyer {
  cursor: pointer;
  font-size: 40px;
  position: absolute;
  top: calc(50% - 2.5px);
  right: -10px;
  transform: translate(-50%, -50%);
}

#app #chatbot .input .envoyer:hover {
  color: #494949;
}

#app #chatbot .reponseIA {
  background-color: #dedede;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
}

#app #vscode {
  flex-direction: column;
  justify-content: start;
  display: flex;
}

#app #vscode textarea {
  resize: vertical;
  border-radius: 10px;
  height: 33.3333%;
  margin: 7.5px 0;
  padding: 10px;
  font-size: 13px;
}
/*# sourceMappingURL=index.25c5aa8a.css.map */
